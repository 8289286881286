import React, { useRef, useState } from "react";
import { Col, Container, Figure, Row } from "react-bootstrap";
import Footer from "../components/Footer";
import Florian from "../assets/FlorianH.jpg";
import { IoPlay } from "react-icons/io5";
import learncover from "../assets/learn/carre_why_cover.jpg";
import { FaPlay } from "react-icons/fa";

const AboutUsPage = () => {
  const blueBallStyle = {
    width: "15px", // Set a smaller width to make it a circle
    height: "15px", // Same as width to make it a circle
    backgroundColor: "#66b2ff", // Blue color
    borderRadius: "50%", // Fully rounded to make it a circle
    padding: "10px", // Use padding to control the size of the circle
  };
  const teamMembers = [
    {
      name: "I. Bici",
      carrePosition: "Co-founder / Head of Business Development",
      position: "Serial Entrepreneur. 25+ years in Telecom, IT, Construction",
      image: "https://images.carre.ai/ilir.f24a3701dbc42ab76a9e-min.png",
    },
    {
      name: "F. Huchedé",
      carrePosition:
        "Co-founder / Exchange expert, quant and blockchain developer",
      position:
        "15+ years financial industry experience, developing new products for exchanges.",
      image: Florian,
    },
    {
      name: "A. Ademi",
      carrePosition: "CTO",
      position: "20+ years in software development",
      image: "https://images.carre.ai/adem.fe35f92097c2670f3567-min.png",
    },
    // Add more team members as needed
  ];

  const team2 = [
    {
      name: "P. Lluka",
      position: "CFO",
      image: "https://images.carre.ai/Pa.636d17e58a851035db50-min.png",
    },
    {
      name: "E. Kaiku",
      position: "Creative Director",
      image: "https://images.carre.ai/Eduart.c612be07c776e853088c-min.png",
    },
    {
      name: "J. McCausland",
      position: "VP of Costumer Relations",
      image: "https://images.carre.ai/Julia.2fbb3ca6ad60946cab81-min.png",
    },
    {
      name: "A. Amzai",
      position: "Front-End Developer",
      image: "https://images.carre.ai/Donatello.2a973bd2d5d0d736cc19-min.png",
    },
    {
      name: "A. Jupolli",
      position: "Legal",
      image: "https://images.carre.ai/AJupolli.ee194b063e85ef727ee9-min.png",
    },
    {
      name: "D. Sadikaj",
      position: "Dev Ops",
      image: "https://images.carre.ai/Tani.bcbdeb256989f8d4dc4d-min.png",
    },
    {
      name: "M. Ademi",
      position: "Back-End, Blockchain Developer",
      image: "https://images.carre.ai/Meriton.2dbbea0bc1765287b747-min.png",
    },
    {
      name: "S. Gvilava",
      position: " Designer",
      image: "https://images.carre.ai/Shota.0ccdb1bda916910a6b32-min.png",
    },

    // {
    //   name: "TBD",
    //   position: " Marketing Director",
    //   image: Avatar,
    // },
  ];
  const ourValues = [
    {
      name: "Transparency",
      text: "We believe in open and honest communication, providing our investors with clear and comprehensive information about our processes, properties, and investment opportunities.",
    },
    {
      name: "Accessibility",
      text: "We strive to make real estate investment accessible to all, breaking down barriers and creating inclusive opportunities for individuals to participate in wealth-building through fractional ownership.",
    },
    {
      name: "Integrity",
      text: "We uphold the highest standards of integrity in our operations, ensuring that our actions align with our investors' best interests and maintaining trust and confidence in our platform.",
    },
    {
      name: "Innovation",
      text: "We embrace innovation and leverage cutting-edge technologies to revolutionize the real estate investment landscape, continuously seeking new ways to enhance the investor experience and drive efficiency.",
    },
    {
      name: "Collaboration",
      text: "We foster a collaborative environment, working closely with our partners, investors, and stakeholders to achieve shared goals and create mutually beneficial outcomes.",
    },
    {
      name: "Empowerment",
      text: "We empower our investors by providing them with the knowledge, tools, and resources necessary to make informed investment decisions and take control of their financial future.",
    },
    {
      name: "Sustainability",
      text: "We prioritize sustainable practices in real estate investment, considering environmental, social, and governance factors to create long-term value and contribute to a more sustainable future.",
    },
    {
      name: "Customer-centricity",
      text: "We put our investors at the center of everything we do, striving to exceed their expectations, provide exceptional customer service, and deliver a seamless and rewarding investment experience.",
    },
  ];
  // const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const handlePlayPause = () => {
    setIsPlaying(true);
    if (videoRef.current) {
      const videoSrc = videoRef.current.src;
      videoRef.current.src =
        videoSrc + (videoSrc.includes("?") ? "&" : "?") + "autoplay=1";
    }
  };

  const [src, setSrc] = useState("https://www.youtube.com/watch?v=7vCvZ-GYTqw");
  const [isPlaying, setIsPlaying] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setSrc(src + "&autoplay=1");
    setIsPlaying(true); // Hide the thumbnail and show the video
  };

  return (
    <>
      <Container className="mt-5 container-sm">
        <h1 className="mb-3 text-black text-center">
          I believe in making real estate investment easy and attainable, no
          matter where you are or how much you want to invest.
        </h1>
        <Figure.Caption className="blockquote-footer mt-3 text-center fs-4">
          F. Huchedé
        </Figure.Caption>

        <h2 className="fw-bold">Our Story</h2>
        <Row className="align-items-center">
          <Col xl={6}>
            <p className="rounded  fs-6">
              At Carré, we've experienced firsthand the challenges and
              limitations of traditional property ownership. It's tough and
              often inaccessible, especially for the younger generation. Rising
              house prices, hefty expenses, slow transactions, and a lack of
              information make it difficult to enter the market. That's why we
              created Carré. We believe everyone should have the opportunity to
              invest in real estate and benefit from its potential. Our
              innovative platform offers a human-centered approach, allowing
              fractional ownership and leveraging technology to make the process
              transparent and accessible. Our mission is simple: to
              revolutionize real estate investment, break down barriers, and
              empower individuals to build wealth through property ownership.
              With Carré, you can overcome financial hurdles, access valuable
              deals, and enjoy the benefits of real estate in a hassle-free way.
              Welcome to a new era of real estate investment.
            </p>
          </Col>
          <Col
            xl={6}
            className="d-flex align-items-center justify-content-center"
          >
            <div>
              {!isPlaying && (
                <div
                  onClick={handleClick}
                  style={{ position: "relative", display: "inline-block" }}
                >
                  <img src={learncover} alt="Video Thumbnail" />
                  <FaPlay
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      fontSize: "3rem",
                      color: "white",
                      cursor: "pointer",
                    }}
                  />
                </div>
              )}
              {isPlaying && (
                <div>
                  <iframe
                    width="1280"
                    height="520"
                    src="https://www.youtube.com/embed/7vCvZ-GYTqw?autoplay=1&mute=1"
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen={false}
                    playsinline
                  ></iframe>
                </div>
              )}
            </div>
            {/*<div className="position-relative">*/}
            {/*  <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center z-5">*/}
            {/*    {!isPlaying && (*/}
            {/*      <a*/}
            {/*        className="btn btn-icon btn-xl btn-primary rounded-circle stretched-link"*/}
            {/*        aria-label="Play video"*/}
            {/*        onClick={handlePlayPause}*/}
            {/*      >*/}
            {/*        <IoPlay />*/}
            {/*      </a>*/}
            {/*    )}*/}
            {/*  </div>*/}
            {/*  <div>*/}
            {/*    {!isPlaying ? (*/}
            {/*      <img*/}
            {/*        src={learncover}*/}
            {/*        className="rounded-3 object-fit-fill"*/}
            {/*        alt="Video cover"*/}
            {/*        height="450px"*/}
            {/*      />*/}
            {/*    ) : (*/}
            {/*      <iframe*/}
            {/*        width="560"*/}
            {/*        height="315"*/}
            {/*        src="https://www.youtube.com/embed/7vCvZ-GYTqw?controls=0"*/}
            {/*        title="YouTube video player"*/}
            {/*        frameBorder="0"*/}
            {/*        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"*/}
            {/*        referrerPolicy="strict-origin-when-cross-origin"*/}
            {/*        allowFullScreen*/}
            {/*        ref={videoRef}*/}
            {/*      ></iframe>*/}
            {/*    )}*/}
            {/*  </div>*/}
            {/*</div>*/}
          </Col>
        </Row>
        <h2 className="fw-bold my-3 pt-3">Our Mission</h2>
        <Row className="align-items-center">
          <Col xl={12}>
            <ul className="list-unstyled">
              <li className="d-flex align-items-center mb-3">
                <div style={blueBallStyle} className="me-3"></div>
                <span>
                  Democratize real estate: Making property ownership accessible
                  to everyone, regardless of financial barriers or market
                  complexities.
                </span>
              </li>
              <li className="d-flex align-items-center mb-3">
                <div style={blueBallStyle} className="me-3"></div>
                <span>
                  Empower investors: Providing individuals with the tools and
                  opportunities to build wealth through fractional ownership and
                  transparent investment processes.
                </span>
              </li>
              <li className="d-flex align-items-center mb-3">
                <div style={blueBallStyle} className="me-3"></div>
                <span>
                  Revolutionize the market: Disrupting traditional real estate
                  practices with innovative technologies and streamlined
                  experiences.
                </span>
              </li>
              <li className="d-flex align-items-center">
                <div style={blueBallStyle} className="me-3"></div>
                <span>
                  Foster financial growth: Enabling individuals to unlock the
                  potential of real estate as a means to achieve long-term
                  financial stability and prosperity.
                </span>
              </li>
            </ul>
          </Col>
        </Row>

        <h2 className="fw-bold my-3 pt-3">Our Leadership and Team</h2>
        <Row>
          {teamMembers.map((teamMember, index) => (
            <Col key={index} xl={4} className="my-3 d-flex">
              <div className="d-flex flex-column align-items-center w-100 h-100 bg-carre-gray rounded p-3">
                <div className="flex-shrink-0 mb-4">
                  <img
                    src={teamMember.image}
                    alt={teamMember.name}
                    className="rounded-circle"
                    style={{ width: "150px", height: "150px" }}
                  />
                </div>
                <div className="text-center">
                  <h5 className="fw-bold mb-0 text-carre-blue">
                    {teamMember.name}
                  </h5>
                  <p className="fw-bold mb-0 mt-1">
                    {teamMember.carrePosition}
                  </p>
                  <p className="text-muted mb-0">{teamMember.position}</p>
                </div>
              </div>
            </Col>
          ))}
        </Row>
        <Row>
          {team2.map((teamMember, index) => (
            <Col key={index} xl={4} md={6} className="my-3">
              <div className="d-flex align-items-center mb-4 rounded p-3">
                <div className="flex-shrink-0">
                  <img
                    src={teamMember.image}
                    alt={teamMember.name}
                    className="rounded-circle"
                    style={{ width: "150px", height: "150px" }}
                  />
                </div>
                <div className="ms-3">
                  <h5 className="fw-bold mb-0 text-carre-blue">
                    {teamMember.name}
                  </h5>
                  <p className="fw-bold mb-0 mt-1">
                    {teamMember.carrePosition}
                  </p>
                  <p className="fw-bold mb-0">{teamMember.position}</p>
                </div>
              </div>
            </Col>
          ))}
        </Row>

        <h2 className="fw-bold text-center my-3">Our Values</h2>
        <Row>
          {ourValues.map((value, index) => (
            <Col key={index} xl={6}>
              <div className="bg-carre-gray p-3 rounded mb-3">
                <p className="text-carre-blue fw-bold h5">{value.name}</p>
                <p>{value.text}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default AboutUsPage;
