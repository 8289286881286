import SubscribeForm from "../components/Subscrition";

import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import "./Home.css";
import { useNavigate, useParams } from "react-router-dom";
import browse from "./../assets/img/browse.svg";
import buyhouse from "./../assets/img/buyhouse.svg";
import select from "./../assets/img/select.svg";
import token from "./../assets/img/token.svg";
import heroImg from "./../assets/img/bg-image.jpg";
import costumers from "./../assets/img/3000costumers.png";
import award from "./../assets/img/premiumProperties.png";
import secRegulated from "./../assets/img/starproperties.jpg";

import callToAction from "./../assets/img/aboutUsHero.png";

import { useTabContext } from "./Dashboard/TabContext";
import React, { useEffect, useState } from "react";
import WhyUsSection from "../components/WhyUsSection";
import HowItWorks from "../components/HowItWorks";
import Testimonials from "../components/Testimonials";
import FaqAccordion from "../components/FaqAccordion";
import { useGetProperty } from "../hooks/useGetProperty";
import LearnVideo from "./LearnVideo";
import BenefitsSection from "../components/BenefitSection";
import { FiMail } from "react-icons/fi";
import { Swiper, SwiperSlide } from "swiper/react";
import { useMutation } from "react-query";
import { useSubscribeUser } from "../hooks/useSubscribeUser";
import { toast } from "react-toastify";
import { FaHome, FaRegBuilding, FaUsers } from "react-icons/fa";
import PropertyCard from "./Property/PropertyHomeCard";
import FeatureSection from "../components/FeatureSection";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { MdOutlineArrowForward } from "react-icons/md";
import YoutubeIcon from "../assets/youtube.svg";
import CalendarPage from "./CalendarPage";

const steps = [
  {
    image: browse,
    title: "Browse Homes",
    description: "Carré homes are pre-vetted for their investment potential",
  },
  {
    image: select,
    title: "Select Properties",
    description:
      "Find the property that matches your needs and decide how much you want to invest",
  },
  {
    image: token,

    title: "Buy Share",
    description: "Select your payment method and submit your order.",
  },
  {
    image: buyhouse,
    title: "Earn Income & Appreciation",
    description: "Carré and the Management Company take care of the rest.",
  },
];

const testimonialData = [
  {
    icon: <FaRegBuilding />,
    headline: "SEC Regulated",
    subtext: "We are regulated by the U.S. Securities and Exchange Commission.",
  },
  {
    icon: <FaUsers />,
    headline: "Experienced Team",
    subtext:
      "Our team boasts over 70 years of combined experience in real estate, fintech, and trading.",
  },
  {
    icon: <FaHome />,
    headline: "Top-tier Property Management",
    subtext:
      "Our property managers are Airbnb Superhosts, ensuring exceptional hospitality and maximizing your investment returns.",
  },
];

function Home() {
  const nav = useNavigate();
  const { setSignUpModalOpen, signUpModalOpen } = useTabContext();
  const { ref } = useParams();
  if (ref) {
    if (ref === "onboarding") {
      localStorage.setItem("onboarding", true);
    } else {
      localStorage.setItem("ref", ref);
    }
  }
  useEffect(() => {
    if (ref === "onboarding") {
      setSignUpModalOpen(true);
    }
  }, [ref]);

  const { mutate, isLoading: subscribeLoading } = useMutation(
    useSubscribeUser,
    {
      onSuccess: (data) => {
        if (process.env.REACT_APP_IS_PRODUCTION !== "false") {
          // eslint-disable-next-line no-undef
          fbq("track", "Lead");
        }
        toast.success("Successfully subscribed");
      },
      onError: (error) => {
        toast.error(error?.response.data.error);
      },
    }
  );

  const [isCheckboxError, setIsCheckboxError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const email = e.target.elements.email.value;
    const shouldReceiveMarketingEmails = e.target.elements.marketing.checked;
    subscribe(email, shouldReceiveMarketingEmails);
  };

  const subscribe = (email, shouldReceiveMarketingEmails) => {
    if (!shouldReceiveMarketingEmails) {
      setIsCheckboxError(true);
    } else {
      mutate(email);
      setIsCheckboxError(false);
    }
  };

  // const [showModal, setShowModal] = useState(false);

  const [market, setMarket] = useState("");
  const [location, setLocation] = useState("");
  const [type, setType] = useState("");
  const { data, isLoading } = useGetProperty({
    market,
    location,
    type,
    limit: 7,
  });

  const navigate = (id) => {
    nav("marketplace/propdetails/" + id);
  };

  // const handleClose = () => setShowModal(false);

  // const handleShow = () => setShowModal(true);
  useEffect(() => {
    // Check if the modal has been opened before
    const hasModalBeenOpened = localStorage.getItem("hasModalBeenOpened");

    if (!hasModalBeenOpened && signUpModalOpen !== true) {
      const timeoutId = setTimeout(() => {
        handleShow();
        // Set the flag in local storage to indicate that the modal has been opened
        localStorage.setItem("hasModalBeenOpened", "true");
      }, 25000);

      // Clear the timeout to avoid opening the modal if the component unmounts
      return () => clearTimeout(timeoutId);
    }
  }, []);

  const openSignupModal = () => {
    setSignUpModalOpen(true);
  };

  const [showModal, setShowModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleCloseVideoModal = () => setShowVideoModal(false);

  const handleShow = () => setSignUpModalOpen(true);

  return (
    <div>
      <SubscribeForm
        showModal={showModal}
        handleClose={handleClose}
        handleShow={handleShow}
      />
      <div>
        <NavBar isHome={true} />
        <section className="position-relative pt-md-2 pt-lg-4 pt-xl-5 pb-5 pb-lg-0 B">
          <div
            className="position-absolute top-0 start-0 w-100 d-none d-lg-block"
            style={{
              height: 792,
              margin: "-1px 0 0 -1px",
              backgroundSize: "75px 75px",
              backgroundColor: "#f4f7fa",
              backgroundImage:
                "linear-gradient(to right, #ebeff3 1px, transparent 1px), " +
                "linear-gradient(to bottom, #ebeff3 1px, transparent 1px)",
            }}
          />
          <div
            className="position-absolute top-0 start-0 w-100 h-100 d-lg-none"
            style={{
              margin: "-1px 0 0 -1px",
              backgroundSize: "75px 75px",
              backgroundColor: "#f4f7fa",

              backgroundImage:
                "linear-gradient(to right, #ebeff3 1px, transparent 1px), " +
                "linear-gradient(to bottom, #ebeff3 1px, transparent 1px)",
            }}
          />
          <div className="container position-relative z-2 pt-5 pb-2 pb-sm-3 pb-lg-0">
            <div
              className="text-center py-5 mt-3 mt-sm-4 mb-md-3 mb-lg-4 mb-xl-5 mx-auto"
              style={{ maxWidth: 760 }}
            >
              <h1 className="display-3 pb-1 pb-sm-2 pb-md-3 text-black fw-semibold">
                Invest in Real Estate For As Little As{" "}
                <span className="d-inline text-warning">{/*Carré*/} $100</span>
              </h1>
              <p className="fs-lg mb-0 pb-1 pb-sm-2 pb-md-3">
                Build your wealth by collecting rent from day one and benefiting
                from appreciation{" "}
              </p>
              <div>
                <Button
                  className="mt-2 rounded-4"
                  variant="warning"
                  onClick={openSignupModal}
                >
                  Sign up for free{" "}
                  <MdOutlineArrowForward className="mx-1" size="25" />
                </Button>
                <Button
                  variant="link"
                  className="mt-2 text-black fw-medium border-bottom border-1 rounded-0"
                  onClick={() => setShowVideoModal(true)}
                  size="sm"
                >
                  <img
                    src={YoutubeIcon}
                    alt="YouTube"
                    style={{
                      width: "22px",
                      height: "22px",
                      marginRight: "10px",
                    }}
                  />
                  How Carré works?
                </Button>

                <Modal
                  show={showVideoModal}
                  onHide={handleCloseVideoModal}
                  size="lg"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>What’s Carré?</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                      <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/_1-XJI6v60w?autoplay=1"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
            {/* Google reviews (visible on screens > 768px) */}
            <div className="position-relative d-none d-md-block">
              <div className="position-absolute" style={{ right: "78.24%" }}>
                <img
                  onClick={() => nav("marketplace")}
                  className="position-relative z-2 rounded-3 custom-hover"
                  src={secRegulated}
                  width={282}
                  alt="Google reviews"
                />
                <div className="position-absolute top-0 start-0 w-100 h-100 bg-light rounded-3 shadow-sm d-dark-mode-none" />
                <div
                  className="position-absolute top-0 start-0 w-100 h-100 rounded-3 d-none d-dark-mode-block"
                  style={{ backgroundColor: "#202327" }}
                />
              </div>
            </div>
            {/* Parallax image */}
            <div
              className="jarallax rounded overflow-hidden z-2"
              data-jarallax=""
              data-speed="0.65"
              style={{ WebkitClipPath: "url(#shape)", clipPath: "url(#shape)" }}
            >
              <div className="d-none d-md-block ratio ratio-21x9" />
              <div className="d-md-none ratio ratio-16x9" />
              <svg
                className="d-none d-md-block"
                width={0}
                height={0}
                viewBox="0 0 1296 506"
              >
                <defs>
                  <clipPath
                    id="shape"
                    clipPathUnits="objectBoundingBox"
                    transform="scale(0.0007716049383, 0.001976284585)"
                  >
                    <path d="M18 110H288C297.941 110 306 101.941 306 92V18C306 8.05887 314.059 0 324 0H1278C1287.94 0 1296 8.05888 1296 18V378C1296 387.941 1287.94 396 1278 396H1008C998.059 396 990 404.059 990 414V488C990 497.941 981.941 506 972 506H18C8.05887 506 0 497.941 0 488V128C0 118.059 8.05887 110 18 110Z" />
                  </clipPath>
                </defs>
              </svg>
              {/*<img*/}
              {/*  className="position-absolute top-0 end-0 z-2 mt-4 me-4"*/}
              {/*  src={award}*/}
              {/*  width={132}*/}
              {/*  alt="Award"*/}
              {/*/>*/}
              <span className="position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-25" />
              <div
                className="jarallax-img"
                style={{
                  backgroundImage: `url(${heroImg})`,
                }}
              />
            </div>
            {/* Clients (visible on screens > 768px) */}
            <div
              className="position-absolute bottom-0 d-none d-md-block"
              style={{ left: "77.5%" }}
            >
              <img
                className="position-relative z-2 d-dark-mode-none rounded-3 custom-hover"
                onClick={() => handleShow()}
                src={costumers}
                width={282}
                alt="Google reviews"
              />

              <div className="position-absolute top-0 start-0 w-100 h-100 bg-light rounded-3 shadow-sm d-dark-mode-none" />
              <div
                className="position-absolute top-0 start-0 w-100 h-100 rounded-3 d-none d-dark-mode-block"
                style={{ backgroundColor: "#202327" }}
              />
            </div>
            {/* Google reviews + Clients (visible on screens < 768px) */}
            <div className="d-flex flex-column flex-sm-row gap-3 pt-3 d-md-none">
              <div className="position-relative d-flex justify-content-center w-100 rounded-3">
                <img
                  className="position-relative z-2 rounded-3 custom-hover"
                  onClick={() => handleShow()}
                  src={costumers}
                  width={282}
                  alt="Google reviews"
                />
                <div className="position-absolute top-0 start-0 w-100 h-100 bg-light rounded-3 shadow-sm d-dark-mode-none" />
                <div
                  className="position-absolute top-0 start-0 w-100 h-100 rounded-3 d-none d-dark-mode-block"
                  style={{ backgroundColor: "#202327" }}
                />
              </div>
              <div className="position-relative d-flex justify-content-center w-100 rounded-3">
                <img
                  onClick={() => nav("marketplace")}
                  className="position-relative z-2 d-dark-mode-none rounded-3 custom-hover"
                  src={secRegulated}
                  width={282}
                  alt="Google reviews"
                />

                <div className="position-absolute top-0 start-0 w-100 h-100 bg-light rounded-3 shadow-sm d-dark-mode-none" />
                <div
                  className="position-absolute top-0 start-0 w-100 h-100 rounded-3 d-none d-dark-mode-block"
                  style={{ backgroundColor: "#202327" }}
                />
              </div>
            </div>
          </div>
        </section>
        <div
          style={{
            backgroundColor: "#77add0",
            marginTop: "60px",
            paddingTop: "20px",
            paddingBottom: "20px",
          }}
        >
          <div className="text-center py-3  pt-4">
            <h2 className="h1 fw-semibold text-white">
              Access Global Rental Property Investments
            </h2>
            <p className="text-white fs-lg">Invest in less than 2 minutes</p>
          </div>
          <div className=" px-4 pb-4 container">
            <Swiper
              slidesPerView={1.2}
              spaceBetween={24}
              loop={true}
              pagination={{ el: "#property-bullets", clickable: true }}
              breakpoints={{
                560: { slidesPerView: 2.2 },
                960: { slidesPerView: 3.2 },
              }}
            >
              {data?.map((p, i) => (
                <SwiperSlide key={i}>
                  <div onClick={() => navigate(p.id)}>
                    <PropertyCard
                      data={p}
                      price={
                        p?.market_stage_name === "Upcoming"
                          ? p?.allowed_token_to_reserve - p?.reserved_token
                          : p?.available_token?.toLocaleString("en-US")
                      }
                      className="h-100 w-100" // Ensure the card also takes full height and width
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>

            <div className="flex mt-4 text-center">
              <a
                className="btn btn-warning rounded-4"
                onClick={() => nav("marketplace")}
              >
                View Properties
              </a>
            </div>
          </div>
        </div>
        {/*<HeroSection />*/}
        <WhyUsSection />
        <HowItWorks />
        <LearnVideo />
        <BenefitsSection />
        <section className=" py-5" style={{ backgroundColor: "#77add0" }}>
          <div className="container pt-lg-2 pt-xl-4 pt-xxl-5 pb-1 pb-sm-3">
            <div className="row pt-sm-3 pt-md-4">
              <div className="col-md-6 col-xl-5 offset-xl-1">
                <h2 className="display-4 fw-semibold text-white">
                  Ready to take your investment to the next level?
                </h2>
              </div>
              <div className="col-md-6 col-lg-5 col-xl-4 offset-lg-1">
                <p className="fs-xl pb-4 mb-2 mb-lg-3  text-white">
                  Stay informed with the latest industry insights and updates.
                  Subscribe to our newsletter for exclusive content and explore
                  our latest case studies.
                </p>

                <form onSubmit={handleSubmit}>
                  <div className="input-group">
                    <span className="input-group-text text-body-secondary">
                      <FiMail />
                    </span>
                    <input
                      className="form-control"
                      type="email"
                      name="email"
                      placeholder="Enter your email"
                      required
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-warning"
                        type="submit"
                        disabled={subscribeLoading}
                      >
                        {subscribeLoading ? "Subscribing..." : "Subscribe"}
                      </button>
                    </div>
                  </div>
                  <div className="form-check mt-2 text-white">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="marketing"
                      name="marketing"
                    />
                    <label
                      className="form-check-label text-white"
                      htmlFor="marketing"
                    >
                      I agree to receive marketing emails
                    </label>
                  </div>
                  {isCheckboxError && (
                    <div className="text-danger">
                      Please agree to receive marketing emails
                    </div>
                  )}
                </form>
              </div>
            </div>
            <div className="d-none d-md-block text-center mt-n5">
              <svg
                className="text-warning ms-lg-5"
                width="171"
                height="97"
                viewBox="0 0 171 97"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M169.319 54.333C162.404 55.9509 155.712 58.0764 149.09 60.6764L149.07 60.6761C148.967 60.7158 148.863 60.7554 148.76 60.7951C147.3 61.3811 148.325 63.4238 149.672 63.2067C154.548 62.4134 159.994 59.8725 164.87 59.0792C148.278 73.1339 129.684 89.2549 107.779 92.6402C85.6981 96.0539 65.5665 86.7839 56.8768 66.9865C70.9662 55.0671 79.2106 35.6614 79.0299 17.6457C78.9484 10.3157 76.1485 -3.36373 65.7068 1.21851C55.8557 5.53146 52.0466 22.5213 50.5736 31.7739C48.7364 43.2858 49.7593 55.5291 53.8643 66.2014C52.787 67.0812 51.6907 67.8989 50.5755 68.6546C40.6328 75.3851 27.1039 78.8929 16.4487 72.0362C2.91045 63.3259 1.93984 44.9485 1.56902 30.4091C1.54778 29.6265 0.359869 29.6092 0.360624 30.3915C0.322634 44.0809 0.835929 59.065 10.5664 69.6857C18.5722 78.4182 30.4315 79.7753 41.3346 75.9924C46.2437 74.2834 50.7739 71.7557 54.8581 68.6348C59.9738 80.2586 68.9965 89.6956 82.2735 93.7393C113.474 103.223 141.744 83.0494 164.903 63.697L161.901 71.0334C161.267 72.5887 163.76 73.2736 164.393 71.7389C165.986 67.8713 167.569 63.9933 169.152 60.1359C169.288 60.0247 169.695 58.6127 169.821 58.491C170.122 57.1161 169.152 60.1359 169.851 58.4169C170.189 57.6087 170.517 56.79 170.855 55.9818C171.248 54.9994 170.185 54.1192 169.319 54.333ZM54.3624 59.8578C51.4872 49.1623 51.6051 37.5841 54.2025 26.8039C55.5185 21.3369 57.4405 15.8066 60.1572 10.8541C61.2311 8.89354 62.5139 6.77134 64.2307 5.31421C69.4231 0.902277 74.3649 4.80357 75.8002 10.4446C80.5272 28.9489 70.1806 51.6898 55.8431 64.5114C55.2971 63.0109 54.793 61.4698 54.3624 59.8578Z"></path>
              </svg>
            </div>
          </div>
        </section>
        <Testimonials />
        <FaqAccordion /> <FeatureSection />
        <Container>
          {/*<div*/}
          {/*  className="rounded-4"*/}
          {/*  style={{*/}
          {/*    backgroundImage: `url(${callToAction})`,*/}
          {/*    minHeight: "40vh",*/}
          {/*    backgroundSize: "cover",*/}
          {/*    backgroundPosition: "center",*/}
          {/*    backgroundRepeat: "no-repeat",*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <div*/}
          {/*    className="bg-opacity-50 bg-carre-gray-bg rounded-4 w-100 d-flex justify-content-center align-items-center"*/}
          {/*    style={{ minHeight: "40vh" }}*/}
          {/*  >*/}
          {/*    <Row className="p-4">*/}
          {/*      <Col className="text-center">*/}
          {/*        <h3 className="h3 fw-semibold text-white">*/}
          {/*          Become a Part of Our Thriving Community*/}
          {/*        </h3>*/}
          {/*        <Button*/}
          {/*          variant="warning"*/}
          {/*          size="lg"*/}
          {/*          className="rounded-4"*/}
          {/*          onClick={openSignupModal}*/}
          {/*        >*/}
          {/*          Sign Up Now*/}
          {/*        </Button>*/}
          {/*      </Col>*/}
          {/*    </Row>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </Container>
        <CalendarPage />
        <Footer />
      </div>
    </div>
  );
}

export default Home;
